.title {
  display: flex;
  justify-content: space-between;
  }
  .balance {
    display: inline-flex;
    width: 100%;
    :link{
      margin-left: 1em;
    }
  }

  .ver-mas{
    display: flex;
    :link{
      display: flex;
    }
    span{
      color: #FF0054;
      margin-left: .5rem;
    }
  }

  .scrolleable {
    table {
      margin-bottom: 1em;
    }
    .btn-gral {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 20%;
    }
  }
