.table-container {
  .scrolleable {
    table {
      tbody {
        tr {
          .balance {
            display: flex;
            align-items: center;
            justify-content: center;
            .balance-info {
              width: 3rem;
              margin: 0;
              justify-self: flex-end;
            }
            .info-btn {
              font-weight: bold;
              justify-self: flex-start;
            }
          }
          td {
            &.centered{
            text-align: center;
            }
            td.password {
              display: flex;
              font-family: var(--font-secondary);

              &:hover {
                img {
                  opacity: 0.8;
                }
              }

              &.seeing-pass {
                button.view-btn {
                  display: none;
                }

                button.hide-btn {
                  display: block;
                }
              }

              button {
                border: none;
                background-color: transparent;
                outline: none;
                width: max-content;
                height: max-content;
                margin: 0 0 0 0.5rem;
                cursor: pointer;
                padding: 0;

                img {
                  width: 1rem;
                  height: 1rem;
                  margin: 0;
                  opacity: 0;
                }

                &.view-btn {
                  display: block;
                }

                &.hide-btn {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
