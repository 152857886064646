.coin-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F2F6FC;
  padding: 1rem;
  border-radius: .4rem;
  h3 {
    font-weight: 400;
    margin: 0;
    span {
      text-transform: uppercase;
    }
  }
  h2 {
    margin: 0;
  }
}
