  .container-fundss{
    background-color: #F2F6FC;
    border-radius: .4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    margin-bottom: 4rem;
    h2{
      align-self: start;
      font-size: 1.3rem;
      margin: 0 0 .8rem;
    }
    .fund {
      display: flex;
      width: 100%;
      .blue{
        background-color: var(--primary-color);
        color: #FFFFFF;
        justify-self: start;
        box-shadow: 0px 0px 20px 5px #c4c7cd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.5rem;
        width:50%;
        height: 100%;
        flex-basis: 30%;
        text-align: center;
        border-radius: .4rem;
        h3 {
          align-self: start;
          margin: 0;
          font-weight: 100;
          font-size: 1.2rem;
        }
        h2{
          font-size: 1.8rem;
          margin: 0;
        }
      }
      .stats {
        width: 100%;
        flex-basis: 80%;
        padding: .5rem 4rem;
        h3{
          font-size: 1.3rem;
          margin: 0 0 .5rem 0;
        }
        section {
          display: flex;
          justify-content: space-between;
          width: 100%;
          div {
            .arrow{
              margin-left: .5rem;
              border-radius: 5px;
            }
            .high{
              border-radius: 5px;
              fill: #CAEEBF;
              background-color: #4BC527;
            }
            .low{
              border-radius: 5px;
              fill: rgb(255, 159, 159);
              background-color: rgb(255, 39, 39);
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
            h2{
              font-size: 2rem;
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            p{
              margin: 0;
              font-size: 1.1rem;
              color: rgb(135, 135, 135);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1220px) {
    .container-fundss{
      .fund {
        display: flex;
        flex-direction: column;
        .blue {
          width: 100%;
          margin: 1rem 0;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 2rem;
          h3 {
            align-self: center;
          }
        }
        .stats {
          padding: 0;
        }
      }
    }
  }

  @media(max-width: 670px) {
    .container-fundss{
      margin: 0 auto;
      margin-bottom: 2rem;
      .blue {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
          font-size: 2rem!important;
        }
      }
      .stats {
        section {
          align-items: flex-start;
          gap: 1rem;
          div {
            width: 100%;
            display: flex;
            justify-content: space-between!important;
          }
        }
      }
    }
  }
