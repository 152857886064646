.title {
  display: flex;
  justify-content: space-between;
}
.balance {
  display: inline-flex;
  width: 100%;
  :link {
    margin-left: 1em;
  }
}

tbody {
  tr {
    td {
      text-align: justify;
    }
  }
}

button {
  border: none;
  background: none;
}
.scrolleable {
  table {
    margin-bottom: 1em;
  }
  .btn-gral {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
  }
}
