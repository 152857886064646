.header-component{
    width: 100%;
    background-color: var(--highlight-color);
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;
    color: rgba(255, 255, 255, 0.9);
    font-size: .9rem;
    position: relative;

    .user-name{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: .5rem;
        height: 100%;
        cursor: pointer;
        &:hover {
          .dropdown-list{
            display: initial;
          }
        }
       .dropdown-list{
          position: absolute;
          top: 4rem;
          width: 10rem;
          right: 0;
          display: none;
          opacity: 1;
          transition: .2s ease-in-out all;

            button{
              height: 2.5rem;
              width: 100%;
              border: none;
              color: white;
              background-color: #000;
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: center;

              &:hover {
                opacity: .8;
              }

              img {
                height: .8rem;
                margin-left: 8px;
              }

            }
        }

    }


    .signout-btn, .mobile-btn{
        background-color: transparent;
        border: none;
        outline: none;
        width: max-content;

        img{
            height: 1rem;
            margin-left: .5rem;
            cursor: pointer;
        }
    }

    .mobile-btn{
        display: none;
    }

    .mobile-background{
        display: none;
    }

}

@media(max-width:1024px){
    .header-component{
        justify-content: space-between;
        padding: 0 1rem;

        .mobile-btn{
            display: block;

            img{
                margin-left: 0;
            }
        }

        .mobile-background{
            width: 100%;
            height: 100vh;
            position: fixed;
            background-color: rgba(0, 0, 0, 0.5);
            left: 0;
            top: 0;
            border: none;
            outline: none;

            &.active{
                display: block;
            }
        }
    }
}
