.nav-component{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    width: 18rem;
    height: 100vh;
    padding: 2rem 0 0 3rem;
    border-radius: 0 2rem 2rem 0;
    box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--font-secondary);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 100;
    
    .logo{
        max-height: 2rem;
        max-width: 80%;
        margin-bottom: 15vh;
        filter: invert(1);

        img{
            height: 100%;
        }
    }

    ul{
        padding: 0;
        list-style: none;

        li{
            margin-bottom: 1rem;
            font-size: .9rem;
            cursor: pointer;
            margin-right: 1rem;
            margin-left: -1rem;



            .resumeList {
                border-radius: 5px;
                padding: 0rem 0rem 0rem 2rem;
                color: #DFDBFF;
                text-decoration: none;
                display: block;
                cursor: pointer;
                width: 100%;
                text-align: left;
                font-size: 1rem;
                font-family: var(--font-secondary);
                position: relative;
                animation-name: view;
                animation-duration: .3s;
                animation-fill-mode: forwards;

                li{
                  margin: .5rem 0;
                }

                li:first-child:before {
                  content: "";
                  height: 100%;
                  width: 2px;
                  background-color: rgba(255, 255, 255, 0.8);
                  position: absolute;
                  left: 1rem;
                  margin: 0;
                  padding: 0;
                  cursor: default;
                }
            }

            @keyframes view {
              0% {
                position: relative;
                top: -50px;
                opacity: 0;
              }

              100%{
                top: 0;
                opacity: 1;
              }
            }

            .oculto{
              display: none;
            }

            .nav-btn {
                border-radius: 5px;
                padding: .8rem 1rem;
                color: #DFDBFF;
                text-decoration: none;
                display: block;
                cursor: pointer;
                width: 100%;
                text-align: left;
                font-size: 1rem;
                font-family: var(--font-secondary);
                display: flex;
                justify-content: space-between;
                align-items: center;


                img {
                  text-align: right;
                }

                .up{
                  transform: rotate(180deg);
                  transition: .5s;
                }

                .down {
                  transition: .5s;
                }

                &:hover, &.active{
                  background-color: rgba(255, 255, 255, 0.1);
                  color:rgba(255, 255, 255, 0.8);
                }
            }

            a{
                border-radius: 5px;
                padding: .8rem 1rem;
                color: #DFDBFF;
                text-decoration: none;
                display: block;

                &:hover, &.active{
                    background-color: rgba(255, 255, 255, 0.1);
                    color:rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
}

@media(max-width:1024px){
    .nav-component{
        width: 80vw;
        transition: .4s ease-in-out all;
        transform: translateX(-80vw);
        box-shadow: none;

        &.active{
            box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
            transform: none;
        }
    }
}
