.title {
display: flex;
justify-content: space-between;
}
.balance {
  display: inline-flex;
  width: 100%;
  :link{
    margin-left: 1em;
  }
}
.content-gral{
  .title {
    p:first-of-type {
      font-size: 1.5rem;
    }
  }
  .cards-funds{
    display: flex;
    flex-direction: column;

    .coins {
      h2 {
        font-size: 1.5rem;
      }
      .grid-coins{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: 2rem;
      }
    }
    .stats-funds {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
    }
  }
}
.buttons{
  width: 40%;
  justify-content: space-around;
  display: flex;
  .btn-gral{
    width: 45%;
    justify-content: center;
    display: flex;
    gap: .5rem;
  }
  :link{
    display: flex;
    text-decoration: none;
  }
  .blue{
    background-color: var(--primary-color);
  }
}
button{
  border: none;
  background:none
}

@media(max-width: 670px){

  .title{
    margin:0
  }

  .content-gral{
    .cards-funds{
      flex-direction: row;
      flex-wrap: wrap;
      .card {
        margin-bottom: 3rem;
        width:40%;
      }
      .coins {
        width: 100%;
        .grid-coins{
          text-align: center;
        }
      }
      .stats-funds {
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
  .buttons{
    width: 60%;
    flex-wrap: wrap;
    .btn-gral{
      width: 50%;
      margin:1rem;
    }
  }

}

@media screen and (max-width: 559px) {
  .content-gral {
    .stats-funds {
      .container-fund {
        width: 100%;
      }
    }
  }

}
