.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, .55);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
}

.modal-open {
  display: flex;
}

.modal-dialog {
  width: 60%;
  height: 50%;
  border-radius: .6rem;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    font-size: 2.5rem;
    margin: 0;
  }

  button {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .stats {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    h3 {
      font-size: 1.7rem;
    }

    p {
      span {
        display: block;
        font-size: 2rem;
        font-weight: bold;
      }
    }

  }

  .total {
    font-size: 1.5rem;
    span {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 750px) {

  .modal-dialog {
    width: 80%;
    height: 70%;

    .stats {
      padding-top: 10rem;
      flex-direction: column;
      overflow: auto;
    }
  }
  }

