.login-page {
  display: flex;
  height: 100vh;

  .login-side {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      width: 55%;

      &.error {
        span {
          position: relative;

          &::after {
            content: '*';
            font-weight: bold;
            color: var(--error);
            margin-left: 2px;
            line-height: 5px;
          }
        }

        input {
          border-color: var(--error);
        }
      }

      .brand {
        width: 100%;
        height: 1.7rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 2rem;

        img {
          height: 100%;
        }
      }

      .btn-gral {
        width: 100%;
        margin-top: 1rem;
        text-transform: uppercase;
      }
    }

  }

  .lateral {
    width: 60%;
    height: 100%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .back{
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .brand{
      filter: invert(1);
      height: 2.6rem;
    }
}

@media(max-width: 992px) {
  .login-page {
    padding: 1.5rem;

    .login-side {
      width: 100%;

      .login-box {
        width: 100%;
        max-width: 500px;
      }
    }

    .lateral {
      display: none;
    }
  }

}

}
