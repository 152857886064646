.title {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}
.balance {
  display: inline-flex;
  width: 100%;
  :link {
    margin-left: 1em;
  }
}

.table-container {
  margin:0;
}

button {
  border: none;
  background: none;
}
.scrolleable {
  table {
    margin-bottom: 1em;
  }
  .btn-gral {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    text-transform: uppercase;
  }
}
