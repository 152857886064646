.title {
  display: flex;
  justify-content: space-between;
  }
  .balance {
    display: inline-flex;
    width: 100%;
    :link{
      margin-left: 1em;
    }
  }
  .double{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .line-double {
    width: 45%;
  }
  .ver-mas{
    border: none;
    background:none;
    display: flex;
    :link{
      color: #FF0054;
      display: flex;
      span{
        margin-left: .5rem;
      }
    }
  }
