.user-registration{
  .content-form{
      width: 70%;
      max-width: 550px;
      margin: 12vh auto 2rem auto;

      .line-form{
          margin-bottom: 2rem;
      }

      .btn-gral{

        justify-content: center;
          width: 100%;
          background-color: var(--highlight-color);

          &.disabled {
            pointer-events: none;
            background-color: gray;
          }
      }
  }
}

.title-page{
  text-align: center;
}

.content-balance{
text-align: center;
}

@media(max-width: 992px){
  .user-registration{
      .content-form{
          width: 100%;
          padding: 0 1rem;
      }
  }
}
