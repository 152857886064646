.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  p {
    font-size: 2.3rem;
    margin: 0;
    flex-basis: 50%;
  }
  }
  .balance {
    display: inline-flex;
    width: 100%;
    :link{
      margin-left: 1em;
    }
  }
  .buttons{
    width: 40%;
    justify-content: space-around;
    display: flex;
    .btn-gral{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
    }
    .btn-gral:first-child {
      margin-right: 1rem;
    }
    :link{
      display: flex;
      text-decoration: none;
    }
    .blue{
      background-color: var(--primary-color);
    }
  }
  button{
    border: none;
    background:none
  }

  @media(max-width: 670px){

    .title{
      flex-direction: column;
      margin: 0;
    }

    .content-gral{
      .container-funds{
        flex-direction: column;

        .fund {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          width:100%;
          .value{
            width: 100%;
            margin-bottom: 1rem;
          }
          .stats {
            section {
              display: flex;
              flex-direction: column;
              div {
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .buttons{
      width: 100%;
      .btn-gral{
        width: 40%;
      }
    }

  }
