.container-fund{
  background-color: #F2F6FC;
  border-radius: .4rem;
  min-width: 31%;
  padding: 1.5rem;
  margin-bottom: 2rem;
  .title{
    align-self: start;
    font-size: 1.3rem;
    margin: 0;
    margin-bottom: 1rem;
  }
  .fund-stats {
    display: flex;
    flex-direction: column;
    .blues{
      background-color: var(--primary-color);
      color: #FFFFFF;
      justify-self: start;
      width: 100%;
      box-shadow: 0px 0px 20px 5px #c4c7cd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem 1.5rem;
      margin-bottom: 2rem;
      border-radius: .4rem;
      .title-price{
        align-self: start;
        margin: 0;
        font-weight: 100;
        font-size: 1.2rem;
      }
      .price-fund{
        font-size: 1.8rem;
        margin: 0
      }
    }
    .valor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 1.5rem;
      width:50%;
      height: 100%;
      flex-basis: 30%;
      text-align: center;
      border-radius: .4rem;
    }
    .datos {
      width: 100%;
      flex-basis: 80%;
      text-align: justify;
      h3{
        font-size: 1.3rem;
        margin: 0 0 .5rem 0;

      }
      section {
        display: flex;
        flex-direction: column;
        align-items: flex-start ;
        width: 100%;
        z-index: -10;
        gap: 1rem;
        div {
          .arrow{
            margin-left: .5rem;
            border-radius: 5px;
          }
          .high{
            border-radius: 5px;
            fill: #CAEEBF;
            background-color: #4BC527;
          }
          .low{
            border-radius: 5px;
            fill: rgb(255, 159, 159);
            background-color: rgb(255, 39, 39);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
          h2{
            font-size: 2rem;
            margin: 0;
            display: flex;
            align-items: center;
          }
          p{
            margin: 0;
            font-size: 1.1rem;
            color: rgb(135, 135, 135);
          }
        }
      }
    }
  }
}

